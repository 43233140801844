import React from 'react';
import {
        Card,
        Button,
        CardImg,
        CardTitle,
        CardText,
        CardColumns,
        CardSubtitle,
        CardBody,
        CardGroup,
        Col
        } from 'reactstrap';
import Electric from '.././Images/Electric.jpg';
import Code from '.././Images/Code.jpg';
import Autom from '.././Images/Autom.jpg';

import { GrTechnology } from "react-icons/gr";
import { GiRobotLeg } from 'react-icons/gi';
import { GiElectricalResistance } from 'react-icons/gi';

const Servicios01 = (props) => {
  return (
    <CardColumns>
        <CardGroup>
          <Card>
            <CardImg
              alt="Card image cap"
              src={Electric}
             // style={{width: '180px'}}//margin: "30px 0", opacity: "0.5"
              top
              width="100%"
            />
          </Card >
          <Card className="text-center" style={{ backgroundColor: '#F2F2F2', borderColor: '#F2F2F2' }}>
            <CardBody>
              <CardTitle tag="h2">
                Ingeniería Eléctrica
              </CardTitle>
              <CardSubtitle>
                <GiElectricalResistance icon='GiElectricalResistance' size='150px' />
              </CardSubtitle>
              <CardText>
                Diseño y Ejecución de proyectos electricos, damos solución a los problemas actuales de la industria, con un enfoque en la calidad y eficiencia de nuestros trabajos.
              </CardText>
              <Button>
                Button
              </Button>
            </CardBody>
          </Card>
        </CardGroup>
        <CardGroup>
            <Card style={{ backgroundColor: '#F2F2F2', borderColor: '#F2F2F2' }}>
            <CardBody>
                <CardTitle tag="h2">
                    Soluciones Automatizadas
                </CardTitle>
              <CardSubtitle>
                <GiRobotLeg icon='GiRobotLeg' size='150px' />
              </CardSubtitle>
              <CardText>
                Nuestro objetivo es generar una industria conectada y moderna, trabajamos con tecnologías de vanguardia aplicadas a nuestros proyectos para satisfacer la creciente demanda de innovación.
              </CardText>
              <Button>
                Button
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardImg
              alt="Card image cap"
              src={Autom}
              top
              width="100%"
            />
          </Card>
        </CardGroup>
        <CardGroup>
          <Card>
            <CardImg
              alt="Card image cap"
              src={Code}
              top
              width="100%"
            />
          </Card>
          <Card style={{ backgroundColor: '#F2F2F2', borderColor: '#F2F2F2' }}>
            <CardBody>
              <CardTitle
              tag="h2">
                Productos de Software
              </CardTitle>
              <CardSubtitle
                tag="h1"
              >
              <GrTechnology icon="GrTechnology" size='150px' />
              </CardSubtitle>
              <CardText>
                Generamos Sofware de calidad, a medida y ajustado a los requerimientos cambiantes de la industria.
                Damos énfasis en la comunicación entre personas,
                te apoyamos desde el momento en que decides solucionar tus problemas a traves de un software
              </CardText>
              <Button>
                Button
              </Button>
            </CardBody>
          </Card>
        </CardGroup>
    </CardColumns>


  );
};

export default Servicios01;

//<Card inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>