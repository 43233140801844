import React from 'react';
import {
        Card,
        Button,
        CardImg,
        CardTitle,
        CardText,
        CardColumns,
        CardSubtitle,
        CardBody,
        CardGroup,
        CardFooter,
        Container,
        Row,
        Col
        } from 'reactstrap';

import logo from '.././Images/Logo01.png';

const Footer = (props) => {
  return (
    <Card style={{ backgroundColor: '#c0c0c0', borderColor: '#c0c0c0' }}>
        <CardBody>
          <CardText>
          <Row>
            <Col className="trasnparent">
              <img
                src={logo}
                style={{width: '300px'}}//margin: "30px 0", opacity: "0.5"
              />
            </Col>
            <Col className="trasnparent" style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
              -Ingenieria Eléctrica
              <br/>
              -Soluciones de Automatizacion
              <br/>
              -Ingeniería de Software
              <br/>
              -Servicios en Terreno
              <br/>
              -Soporte Técnico Emergencia
            </Col>
            <Col className="trasnparent">
              Contacto<br/>
              <br/>
              <br/>
              contacto@lynxeng.cl<br/>
            </Col>
          </Row>

          </CardText>
        </CardBody>
        <Card inverse className="text-center" style={{ backgroundColor: '#333333', borderColor: '#333333' }}>
        Desarrollado por Lynx Engineering Chile® 2022
        </Card>
     </Card>
  );
};
export default Footer;