import React, { Component } from "react";
import {    Collapse,
            Navbar,
            NavbarToggler,
            NavbarBrand,
            Nav,
            NavItem,
            NavLink,
            UncontrolledDropdown,
            DropdownToggle,
            DropdownMenu,
            DropdownItem,
            NavbarText  } from "reactstrap";

import logo from '.././Images/Logo01.png';

class Header extends Component {
  render() {
    return (
            <div>
              <Navbar
                color="light"
                expand="md"
                light
                fixed='top'
                style={{opacity: '0.9'}}
              >
                <NavbarBrand href="/">

                  <img
                    src={logo}
                    style={{width: '180px'}}//margin: "30px 0", opacity: "0.5"
                  />
                </NavbarBrand>
                <NavbarToggler onClick={function noRefCheck(){}} />
                <Collapse navbar>
                  <Nav
                    className="me-auto"
                    navbar
                  >
                    <NavItem>
                      <NavLink href="/">
                        Servicios
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink>
                        Quienes Somos
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown
                      inNavbar
                      nav
                    >
                      <DropdownToggle
                        caret
                        nav
                      >
                        Options
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          Esta no
                        </DropdownItem>
                        <DropdownItem>
                          Esta tampoco
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          Reset
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                  <NavbarText>
                    contacto@lynxeng.cl
                  </NavbarText>
                </Collapse>
              </Navbar>
            </div>
            );
  }
}

export default Header;
