import Header from "./components/Header";
import HomeCarousel from "./components/Carousel";
import Servicios01 from "./components/Cards_Servicios01";
import Footer from "./components/Footer";
import './App.css';

function App() {
  return (
<div className="App">
      <div style={{height:"130px"}}></div>
      <Header />
      <HomeCarousel />
      <Servicios01 />
      <Footer />
</div>
  );
}

export default App;
