//Importaciones
import React, { Component } from "react";
import {
        Carousel,
        CarouselControl,
        CarouselItem,
        CarouselIndicators,
        CarouselCaption,
        //UncontrolledCarousel
        } from "reactstrap";

//Importacion Imagenes
import Img_Ind4 from '.././Images/Ind4.jpg';
import Img_Amb from '.././Images/Amb.jpg';
import Img_Gest from '.././Images/Gest.jpg';


const items = [
              {
                header: 'Conectando la industria',
                //caption: 'Conectando la industria',
                captionTextito: '.',
                //key: 1,
                src: Img_Ind4
              },
              {
                //altText: 'gg',
                header: 'Comprometidos con el medioambiente',
                //caption: 'Comprometidos con el medioambiente',
                captionTextito: '.',
                //captionHeader: 'asdasdasdasdasd',
                //key: 2,
                src: Img_Amb

              },
              {
                header: 'Avanzando hacia la transformación digital',
                //altText: 'soloyotengoalttext',
                //caption: 'ggggggggggggggggg',
                captionTextito: '.',
                //key: 3,
                src: Img_Gest
              }
              ];

//Largo del array items
const itemLength = items.length - 1

class HomeCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

      onExiting() {
        this.animating = true;
      }

      onExited() {
        this.animating = false;
      }

      next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
      }

      previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
      }

      goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
      }

      render() {

        const { activeIndex } = this.state;

        const slides = items.map((item) => {
          return (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={item.src}
            >
              <img src={item.src} alt={item.altText} width="100%"/>
             <CarouselCaption captionText={item.captionTextito} captionHeader={item.header} className="text-dark" style={{fontSize:"50px"}}/>
            </CarouselItem>

          );
        });

        return (
          <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel>
        );
      }
}

//Component End
export default HomeCarousel;


/*
return (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={item.src}
      >

        <div className='carouselCont'>
          <div className='ImgCont'>
            <img width='100%' src={item.src} alt={item.altText} />
          </div>
          <div className='TextCont'>
            <CarouselCaption captionHeader={item.header}  captionText={item.caption}  />
          </div>
        </div>
      </CarouselItem>
      );
    });

    return (
      <div>
        <Carousel className='trustedMechCarousel' activeIndex={activeIndex} next={this.next} previous={this.previous}>
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      </div>


           <img src={item.src} alt={item.altText} width="100%"/> //height="700px" />
      */